<template>
  <div class="login">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
    >
      <h3 class="title">三茗科技项目授权中心</h3>
      <el-form-item>
        <el-input
          placeholder="账号"
          v-model="loginForm.userName"
          prefix-icon="el-icon-s-custom"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="密码"
          type="password"
          v-model="loginForm.password"
          prefix-icon="el-icon-s-goods"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="medium"
          style="width: 100%"
          :loading="loading"
          @click.native.prevent="handleLogin"
          >登 录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "login",
  data() {
    return {
      loginForm: {
        userName: "",
        password: ""
      },
      loginRules: {},
      loading: false
    };
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    handleLogin() {
      this.loading = true;
      let data = {
        password: this.loginForm.password,
        username: this.loginForm.userName
      };
      this.login(data);
    },
    login(data) {
      this.post(this.config.apiUrl.login, data)
        .then(res => {
          console.log(res);
          this.loading = false;
          if (res.code == 200) {
            this.msgSuccess(res.message);
            this.$store.dispatch("setUserInfo", JSON.stringify(res.result.userInfo));
            this.$store.dispatch("setToken", res.result.token);
            // console.log(this.$store.getters.token)
            this.$router.push({
              path: "notice"
            });
          } else {
            this.loading = false;
            this.msgError(res.message);
          }
        })
        .catch(err => {
          this.loading = false;
        });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/image/login-background.png");
  background-size: cover;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
</style>
